/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import TabPanel from "../shared/components/TabPanel";
import Timecards from "../timecards/Timecards";
import TimecardDetail from "../timecards/TimecardDetail";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { withSnackbar } from "notistack";
import NewTimecardDialog from "../shared/components/NewTimecardDialog";
import { Button } from "@mui/material";
import {
  QueueTabs,
  TimesheetFileTypes,
  JobAssignmentFileTypes,
  TimesheetStatus,
  ErrorMessages,
  PayrollLockType,
  NoteType,
  TimecardUploadSource,
  TimesheetSubStatusMap,
} from "../../commons/Enums";
import LoggedUser from "../../commons/LoggedUser";
import TimesheetApi from "../../api/TimesheetApi";
import { downloadPayrollReport, downloadBillingReport } from "../shared/functions/ReportDownload";
import JobAssignmentApi from "../../api/JobAssignmentApi";
import PayrollApi from "../../api/PayrollApi";
import PayrollLocksApi from "../../api/PayrollLocksApi";
import Features from "../../commons/Features";
import TimercardAddButton from "../shared/components/TimecardAddButton";
import { connect } from "react-redux";
import { TITLE_CHANGED } from "../../redux/actions/actionTypes";
import moment from "moment";
import { parseErrorResponse } from "commons/utils";
import { HomeTabs } from "./components/HomeTabs";

const ERROR_UPLOADING_FILE = "An error occurred while uploading the file.";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: QueueTabs.PENDING,
      createTimecardDialogOpen: false,
      myAssignments: null,
      forReviewAssignments: null,
      approvedAssignments: null,
      lateAssignments: null,
      allAssignments: null,
      selectedAssignmentId: null,
      billingAssignments: null,
      assignments: null,
      staffUserId: null,
      triggerBillingOnChange: true,
      isAdjustment: false,
      canOpenTimecardAutomatically: false,
    };

    this.updateSelectedAssignment = this.updateSelectedAssignment.bind(this);
    this.onNotWorkedAndNoExpenses = this.onNotWorkedAndNoExpenses.bind(this);
    this.onConvertExpenseTimecard = this.onConvertExpenseTimecard.bind(this);
    this.onTimecardFileUploaded = this.onTimecardFileUploaded.bind(this);
    this.onTimecardUploadDelete = this.onTimecardUploadDelete.bind(this);
    this.onFacilityReportFileUploaded = this.onFacilityReportFileUploaded.bind(this);
    this.onFacilityReportFilesAdded = this.onFacilityReportFilesAdded.bind(this);
    this.onConfirmationPageFileUploaded = this.onConfirmationPageFileUploaded.bind(this);
    this.onTimecardEntriesChange = this.onTimecardEntriesChange.bind(this);
    this.onTimecardAdjustmentEntriesChange = this.onTimecardAdjustmentEntriesChange.bind(this);
    this.onBillingEntriesChange = this.onBillingEntriesChange.bind(this);
    this.onTaxableExpensesChange = this.onTaxableExpensesChange.bind(this);
    this.onBonusExpensesChange = this.onBonusExpensesChange.bind(this);
    this.onNonTaxableExpensesChange = this.onNonTaxableExpensesChange.bind(this);
    this.onNonTaxableAdjustmentsChange = this.onNonTaxableAdjustmentsChange.bind(this);
    this.onAdpCodeUpdated = this.onAdpCodeUpdated.bind(this);
    this.onTimesheetCallOffChange = this.onTimesheetCallOffChange.bind(this);
    this.onMessagesRead = this.onMessagesRead.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onReturn = this.onReturn.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onEditRequest = this.onEditRequest.bind(this);
    this.onApprove = this.onApprove.bind(this);
    this.onBilling = this.onBilling.bind(this);
    this.onNotesRetrieved = this.onNotesRetrieved.bind(this);
    this.onNoteChange = this.onNoteChange.bind(this);
    this.onNotifyTravelerChange = this.onNotifyTravelerChange.bind(this);
    this.onNoteSave = this.onNoteSave.bind(this);
    this.onTimecardDetailFetched = this.onTimecardDetailFetched.bind(this);
    this.onSaveApproved = this.onSaveApproved.bind(this);
    this.closeCreateTimecardDialog = this.closeCreateTimecardDialog.bind(this);
    this.openCreateTimecardDialog = this.openCreateTimecardDialog.bind(this);
    this.onTimecardDelete = this.onTimecardDelete.bind(this);
    this.onLateTimecard = this.onLateTimecard.bind(this);
    this.onDidNotWorkReset = this.onDidNotWorkReset.bind(this);
    this.onRateChangesAcknowledged = this.onRateChangesAcknowledged.bind(this);
    this.fetchAssignments = this.fetchAssignments.bind(this);

    const isSalesRep = LoggedUser.isSalesRep();
    const isAdmin = LoggedUser.isAdmin();
    const isSuperAdmin = LoggedUser.isSuperAdmin();
    const { recruiterId, salesRepSelected, vmsId } = this.props.match.params;
    const isSalesRepSelected = salesRepSelected && JSON.parse(salesRepSelected);

    const canOpenTimecardAutomatically = isAdmin || isSuperAdmin;

    this.state = {
      selectedTabIndex: QueueTabs.PENDING,
      createTimecardDialogOpen: false,
      myAssignments: null,
      forReviewAssignments: null,
      approvedAssignments: null,
      lateAssignments: null,
      allAssignments: null,
      selectedAssignmentId: null,
      billingAssignments: null,
      assignments: null,
      staffUserId: null,
      isSalesRepSelected,
      isSalesRep: isSalesRep || isSalesRepSelected,
      currentRecruiterId: recruiterId,
      triggerBillingOnChange: true,
      vmsId: vmsId,
      anchorEl: null,
      canOpenTimecardAutomatically: canOpenTimecardAutomatically,
    };
  }

  updateAssignments(oldAssignments, data) {
    oldAssignments = oldAssignments || {};
    return {
      ...oldAssignments,
      ...data.reduce((newAssignments, d) => {
        newAssignments[d.id] = { ...oldAssignments[d.id], ...d };
        return newAssignments;
      }, {}),
    };
  }

  componentDidMount() {
    this.props.titleChanged();
    const { selectedAssignmentId } = this.props.match.params;
    const startDate = `${this.props.match.params.fdMonth}/${this.props.match.params.fdDay}/${this.props.match.params.fdYear}`;
    const endDate = `${this.props.match.params.ldMonth}/${this.props.match.params.ldDay}/${this.props.match.params.ldYear}`;

    if (
      (this.state.currentRecruiterId || this.state.vmsId) &&
      !this.state.assignments &&
      !selectedAssignmentId
    ) {
      this.getActivePeriodDates();
      this.fetchAssignments();
    } else {
      this.fetchAssignments(startDate, endDate).then(() => {
        this.updateSelectedAssignment(selectedAssignmentId.toLowerCase());
      });
      this.setState({
        firstDate: startDate,
        lastDate: endDate,
      });
    }
  }

  goToTimesheet(id, assignments, recruiterId) {
    if (assignments == null) assignments = this.state.assignments;

    const assignment = assignments[id];

    if (!id || !assignment)
      return {
        selectedTabIndex: QueueTabs.PENDING,
        scrollIntoId: null,
        selectedAssignmentId: null,
      };

    let tab = QueueTabs.ALL_TRAVELERS;
    /* If the logged user is not the recruiter of the assignment, switch to all travelers queue */
    if (assignment.recruiterId === recruiterId) {
      if (
        assignment.status === TimesheetStatus.DRAFT ||
        assignment.status === TimesheetStatus.RETURNED ||
        assignment.status === TimesheetStatus.REJECTED
      ) {
        tab = QueueTabs.PENDING;
      } else if (assignment.status === TimesheetStatus.FOR_REVIEW) {
        tab = QueueTabs.FOR_REVIEW;
      } else if (assignment.status === TimesheetStatus.BILLING) {
        tab = QueueTabs.BILLING;
      } else {
        tab = QueueTabs.APPROVED;
      }
    }

    return {
      selectedTabIndex: tab,
      scrollIntoId: id,
      selectedAssignmentId: id,
    };
  }

  async fetchAssignments(firstDate, endDate) {
    if (firstDate == null && endDate == null) {
      firstDate = this.state.firstDate;
      endDate = this.state.lastDate;
    }

    if (this.state.isSalesRep) {
      const data = await JobAssignmentApi.getBySalesRepTimecardPeriod(
        this.state.currentRecruiterId,
        firstDate,
        endDate
      );
      this.updateTimecardsState(data);
    } else if (this.state.vmsId) {
      const data = await JobAssignmentApi.getByVmsTimecardPeriod(
        this.state.vmsId,
        firstDate,
        endDate
      );
      this.updateTimecardsState(data);
    } else {
      const data = await JobAssignmentApi.getByRecruiterTimecardPeriod(
        this.state.currentRecruiterId,
        firstDate,
        endDate
      );
      this.updateTimecardsState(data);
    }
  }

  updateTimecardsState(data) {
    this.setState((state) => {
      const assignments = this.updateAssignments(state.assignments, data);
      return {
        myAssignments: data
          .filter(
            (d) =>
              d.status === TimesheetStatus.DRAFT ||
              d.status === TimesheetStatus.RETURNED ||
              d.status === TimesheetStatus.REJECTED
          )
          .map((d) => d.id),
        forReviewAssignments: data
          .filter((d) => d.status === TimesheetStatus.FOR_REVIEW)
          .map((d) => d.id),
        billingAssignments: data
          .filter((d) => d.status === TimesheetStatus.BILLING)
          .map((d) => d.id),
        approvedAssignments: data
          .filter(
            (d) =>
              d.status === TimesheetStatus.APPROVED ||
              d.status === TimesheetStatus.LATE ||
              d.status === TimesheetStatus.NOTIMECARD
          )
          .map((d) => d.id),
        allAssignments: data.map((d) => d.id),
        assignments: assignments,
        staffUserId: this.state.currentRecruiterId,
      };
    });
  }

  updateSelectedAssignment(id) {
    if (this.state.selectedAssignmentId) {
      /* If its the same item we ignore it */
      if (id === this.state.selectedAssignmentId) return;

      const selectedAssignment = this.state.assignments[this.state.selectedAssignmentId];
      /* Block timecard switch until operations ends to prevent asigning incorrect states on callbacks */
      if (
        selectedAssignment &&
        (selectedAssignment.saving || selectedAssignment.uploading || selectedAssignment.savingNote)
      ) {
        this.props.enqueueSnackbar("Operation in progress. Please wait.");
        return;
      }
    }

    this.setState({
      selectedAssignmentId: id,
    });

    this.setSelectedItemTabIndex(id);
  }

  setSelectedItemTabIndex(id) {
    if (this.state.myAssignments.indexOf(id) > -1) {
      return;
    }
    if (this.state.forReviewAssignments.indexOf(id) > -1) {
      this.setState({ selectedTabIndex: QueueTabs.FOR_REVIEW });
      return;
    }
    if (this.state.billingAssignments.indexOf(id) > -1) {
      this.setState({ selectedTabIndex: QueueTabs.BILLING });
    } else {
      this.setState({ selectedTabIndex: QueueTabs.APPROVED });
    }
  }

  onTimecardFileUploaded(files) {
    this.setAssignmentProperty({
      uploading: this.state.uploading | TimesheetFileTypes.TIMECARD,
    });

    TimesheetApi.fileUpload("timecard", this.state.selectedAssignmentId, files)
      .then((results) => {
        if (!results.ok) throw results;

        this.setAssignmentProperty({
          timecardUploadId: this.state.selectedAssignmentId,
          timecardUploadSource: TimecardUploadSource.Manual,
          isDigitalTimecardEntry: false,
          uploading: this.state.uploading & ~TimesheetFileTypes.TIMECARD,
          status: TimesheetStatus.DRAFT,
        });
      })
      .catch((error) => {
        this.setAssignmentProperty({
          uploading: this.state.uploading & ~TimesheetFileTypes.TIMECARD,
        });

        parseErrorResponse(error, ERROR_UPLOADING_FILE).then((errorMessage) => {
          this.props.enqueueSnackbar(errorMessage);
        });
      });
  }

  onTimecardUploadDelete() {
    const id = this.state.selectedAssignmentId;
    PayrollApi.deleteTimecardUpload(this.state.selectedAssignmentId)
      .then((blob) => {
        this.setAssignmentProperty({
          timecardUploadId: null,
          uploading: this.state.uploading & ~TimesheetFileTypes.TIMECARD,
        });
        this.setState({ selectedAssignmentId: null });
        this.setState({ selectedAssignmentId: id });
      })
      .catch((error) => {
        this.props.enqueueSnackbar("An error occurred while deleting the file.");
      });
  }

  onConfirmationPageFileUploaded(files) {
    this.setAssignmentProperty({
      confirmationPageUploading:
        this.state.confirmationPageUploading | JobAssignmentFileTypes.CONFIRMATION_PAGE,
    });

    JobAssignmentApi.fileUpload(
      "confirmationPage",
      this.state.assignments[this.state.selectedAssignmentId].jobId,
      files
    )
      .then((results) => {
        if (!results.ok) throw results;
        this.fetchAssignments();
        this.setAssignmentProperty({
          confirmationPageUploadId:
            this.state.assignments[this.state.selectedAssignmentId].confirmationPageUploadId,
          confirmationPageUploading:
            this.state.confirmationPageUploading & ~JobAssignmentFileTypes.CONFIRMATION_PAGE,
        });
      })
      .catch((error) => {
        this.setAssignmentProperty({
          confirmationPageUploading:
            this.state.confirmationPageUploading & ~JobAssignmentFileTypes.CONFIRMATION_PAGE,
        });

        parseErrorResponse(error, ERROR_UPLOADING_FILE).then((errorMessage) => {
          this.props.enqueueSnackbar(errorMessage);
        });
      });
  }

  onFacilityReportFileUploaded(files) {
    this.setAssignmentProperty({
      uploading: this.state.uploading | TimesheetFileTypes.FACILITY_REPORT,
    });

    TimesheetApi.fileUpload("facilityReport", this.state.selectedAssignmentId, files)
      .then((results) => {
        if (!results.ok) throw results;

        this.setAssignmentProperty({
          facilityReportUploadId: this.state.selectedAssignmentId,
          uploading: this.state.uploading & ~TimesheetFileTypes.FACILITY_REPORT,
        });
      })
      .catch((error) => {
        this.setAssignmentProperty({
          uploading: this.state.uploading & ~TimesheetFileTypes.FACILITY_REPORT,
        });

        parseErrorResponse(error, ERROR_UPLOADING_FILE).then((errorMessage) => {
          this.props.enqueueSnackbar(errorMessage);
        });
      });
  }

  /**
   * @param {FileList} files
   */
  onFacilityReportFilesAdded(files) {
    this.setAssignmentProperty({
      uploading: this.state.uploading | TimesheetFileTypes.FACILITY_REPORT,
    });

    const filesArray = Array.from(files);
    TimesheetApi.addFiles("facilityReport", this.state.selectedAssignmentId, filesArray)
      .then(async (results) => {
        if (!results.ok) throw results;

        const resClone = await results.clone();
        const res = await resClone.json();

        if (res.errors !== null) {
          const errors = res.errors.join("\n");
          this.props.enqueueSnackbar(errors);
        }

        this.setAssignmentProperty({
          facilityReportUploadId: this.state.selectedAssignmentId,
          uploading: this.state.uploading & ~TimesheetFileTypes.FACILITY_REPORT,
        });
      })
      .catch((error) => {
        this.setAssignmentProperty({
          uploading: this.state.uploading & ~TimesheetFileTypes.FACILITY_REPORT,
        });

        parseErrorResponse(error, ERROR_UPLOADING_FILE).then((errorMessage) => {
          this.props.enqueueSnackbar(errorMessage);
        });
      });
  }

  /**
   * @param {0|1} subStatus
   */
  onNotWorkedAndNoExpenses(subStatus) {
    const assignment = this.state.assignments[this.state.selectedAssignmentId];

    this.setAssignmentProperty({ saving: true });
    this.setAssignmentProperty({ noExpenses: true });

    TimesheetApi.notWorkedAndNoExpenses(assignment.id, subStatus)
      .then((result) => {
        if (!result.ok) throw result;

        this.props.enqueueSnackbar(
          "Timecard status updated to traveler " +
            TimesheetSubStatusMap[subStatus] +
            " successfully."
        );

        this.setState({
          myAssignments: null,
          forReviewAssignments: null,
          billingAssignments: null,
          approvedAssignments: null,
          lateAssignments: null,
          allAssignments: null,
          assignments: null,
          createTimecardDialogOpen: false,
          selectedAssignmentId: null,
        });

        this.fetchAssignments();
      })
      .catch((error) => {
        this.setAssignmentProperty({ saving: false });
        this.props.enqueueSnackbar("An error occurred while saving the timecard information.");
      });
  }

  async onConvertExpenseTimecard() {
    try {
      const assignment = this.state.assignments[this.state.selectedAssignmentId];

      this.setAssignmentProperty({ saving: true });

      await TimesheetApi.convertToExpenseTimecard(assignment.id);

      this.props.enqueueSnackbar(
        "The timecard has been converted to expense only and moved to approve."
      );

      this.setState({
        myAssignments: null,
        forReviewAssignments: null,
        billingAssignments: null,
        approvedAssignments: null,
        lateAssignments: null,
        allAssignments: null,
        assignments: null,
        createTimecardDialogOpen: false,
        selectedAssignmentId: null,
      });

      this.fetchAssignments();
    } catch (error) {
      this.setAssignmentProperty({ saving: false });
      this.props.enqueueSnackbar("An error occurred while saving the timecard information.");
    }
  }

  onDidNotWorkReset() {
    const previouslySelected = this.state.selectedAssignmentId;
    this.setAssignmentProperty({ saving: true });
    this.setAssignmentProperty({ noExpenses: false });

    this.props.enqueueSnackbar("Timecard status updated to Pending.");

    this.setState({
      myAssignments: null,
      forReviewAssignments: null,
      billingAssignments: null,
      approvedAssignments: null,
      lateAssignments: null,
      allAssignments: null,
      assignments: null,
      createTimecardDialogOpen: false,
      selectedAssignmentId: null,
    });

    this.fetchAssignments().then(() => {
      this.setState({
        ...this.goToTimesheet(previouslySelected, this.state.assignments, this.state.staffUserId),
      });
    });
  }

  onRateChangesAcknowledged() {
    const previouslySelected = this.state.selectedAssignmentId;
    this.setAssignmentProperty({ saving: true, noExpenses: false });

    this.props.enqueueSnackbar("Timecard rates changes have been acknowledged.", {
      variant: "success",
    });

    this.setState({
      myAssignments: null,
      forReviewAssignments: null,
      billingAssignments: null,
      approvedAssignments: null,
      lateAssignments: null,
      allAssignments: null,
      assignments: null,
      createTimecardDialogOpen: false,
      selectedAssignmentId: null,
    });

    this.fetchAssignments().then(() => {
      this.setState({
        ...this.goToTimesheet(previouslySelected, this.state.assignments, this.state.staffUserId),
      });
    });
  }

  submitSelectedAssignment(additionalProps) {
    this.setState((state) => {
      const myAssignments = state.myAssignments.filter((a) => {
        return a !== state.selectedAssignmentId;
      });
      const billingAssignments = state.billingAssignments.filter((a) => {
        return a !== state.selectedAssignmentId;
      });
      const approvedAssignments = state.approvedAssignments.filter((a) => {
        return a !== state.selectedAssignmentId;
      });
      return {
        myAssignments: myAssignments,
        billingAssignments: billingAssignments,
        approvedAssignments: approvedAssignments,
        selectedAssignmentId: this.getNextSelectedAssignment(state.selectedAssignmentId),
        assignments: {
          ...state.assignments,
          [state.selectedAssignmentId]: {
            ...state.assignments[state.selectedAssignmentId],
            status: TimesheetStatus.FOR_REVIEW,
            ...additionalProps,
          },
        },
      };
    });
  }

  getNextSelectedAssignment(selectedId) {
    if (
      this.state.selectedTabIndex === QueueTabs.PENDING &&
      this.state.canOpenTimecardAutomatically
    ) {
      const pendingQueue = Object.values(this.state.assignments).filter?.(
        (x) => x.status === TimesheetStatus.DRAFT
      );
      const nextPDFIndex = pendingQueue.findIndex(
        (a) => a.id !== selectedId && !!a.timecardUploadId
      );
      if (nextPDFIndex >= 0) {
        const nextId = this.state.assignments[pendingQueue[nextPDFIndex].id].id;
        if (nextId) return nextId;
      } else {
        return this.state.myAssignments?.[0] ?? null;
      }
    }

    const billingIsOn = Features.BillingIsOn(this.props.featureFlags);
    if (
      this.state.selectedTabIndex ===
      (billingIsOn ? QueueTabs.ALL_TRAVELERS : QueueTabs.ALL_TRAVELERS - 1)
    ) {
      /* If we are in all travelers, we stay on the same item */
      return this.state.selectedAssignmentId;
    } else {
      let queue;
      switch (this.state.selectedTabIndex) {
        /*  case this.BillingIsOn("billing")
          ? QueueTabs.FOR_REVIEW
          : QueueTabs.FOR_REVIEW:
          queue = this.state.forReviewAssignments;
          break; */
        case QueueTabs.FOR_REVIEW:
          queue = this.state.forReviewAssignments;
          break;
        case QueueTabs.BILLING:
          queue = this.state.billingAssignments;
          break;
        default:
          queue = this.state.myAssignments;
          break;
      }
      //var queue = this.state.selectedTabIndex === QueueTabs.FOR_REVIEW ? this.state.forReviewAssignments : this.state.myAssignments;
      if (queue.length <= 1) {
        return null;
      } else {
        const i = queue.findIndex((a) => a === this.state.selectedAssignmentId);
        if (i === -1) {
          /*TODO: Log this error somewhere. The for_review timecard was not in the pending tab.*/
        }
        if (i === queue.length - 1) {
          /* It was the last element of the list */
          /* We take the assignment index of the previous assignment in this case */
          return queue[i - 1];
        } else {
          /* Otherwise we select the next one on the list */
          return queue[i + 1];
        }
      }
    }
  }

  onTimecardEntriesChange(
    _,
    timecardEntries,
    timecardAdjustments,
    billingEntries,
    taxableExpenses,
    nonTaxableExpenses,
    dtHoursChanged
  ) {
    this.setAssignmentProperty({
      timecardEntries: timecardEntries,
      timecardAdjustmentTypes: timecardAdjustments,
      billingEntries: billingEntries,
      taxableExpenses: taxableExpenses,
      nonTaxableExpenses: nonTaxableExpenses,
      dirtyTimecard: true,
      dtHoursChanged: dtHoursChanged,
    });

    //The OnBillingChanges can only be triggered when a change happens in the billing section, not in the Timecard
    this.setState({ triggerBillingOnChange: false });
  }

  onTimecardAdjustmentEntriesChange(
    _,
    timecardEntries,
    timecardAdjustments,
    billingEntries,
    taxableExpenses,
    nonTaxableExpenses
  ) {
    this.setAssignmentProperty({
      timecardEntries: timecardEntries,
      timecardAdjustmentTypes: timecardAdjustments,
      billingEntries: billingEntries,
      taxableExpenses: taxableExpenses,
      nonTaxableExpenses: nonTaxableExpenses,
      dirtyTimecardAdjustments: true,
    });

    //The OnBillingChanges can only be triggered when a change happens in the billing section, not in the Timecard
    this.setState({ triggerBillingOnChange: false });
  }

  onBillingEntriesChange(billingEntries) {
    if (this.state.triggerBillingOnChange) {
      this.setAssignmentProperty({
        billingEntries: billingEntries,
        dirtyBillingEntries: true,
      });
      this.fetchAssignments();
    }
    this.setState({ triggerBillingOnChange: true });
  }

  onTimecardDetailFetched(detail) {
    this.setAssignmentProperty(detail);
  }

  onTaxableExpensesChange(jobAssignment, expenses) {
    this.setAssignmentProperty({
      taxableExpenses: expenses,
      dirtyExpenses: true,
      noExpenses: false,
    });
  }

  onNonTaxableExpensesChange(jobAssignment, expenses) {
    this.setAssignmentProperty({
      nonTaxableExpenses: expenses,
      dirtyExpenses: true,
      noExpenses: false,
    });
  }

  onBonusExpensesChange(jobAssignment, expenses) {
    this.setAssignmentProperty({
      bonusExpenses: expenses,
      dirtyExpenses: true,
      noExpenses: false,
    });
  }

  onNonTaxableAdjustmentsChange(jobAssignment, adjustments) {
    this.setAssignmentProperty({
      nonTaxableAdjustmentTypes: adjustments,
      dirtyExpenses: true,
    });
  }

  /**
   *
   * @param {any[]} callOffs
   */
  onTimesheetCallOffChange(callOffs) {
    this.setAssignmentProperty({
      callOffEntries: callOffs,
      dirtyCallOffs: true,
    });
  }

  onMessagesRead() {
    this.setAssignmentProperty({
      hasUnreadMessages: false,
    });
  }

  /**
   * @param {string|null} adpCode
   */
  async onAdpCodeUpdated(adpCode) {
    const assignment = this.state.assignments[this.state.selectedAssignmentId];

    if (assignment.adpCode === adpCode) {
      return;
    }

    this.setAssignmentProperty({
      adpCode: adpCode,
    });

    const jobAssignmentId = this.state.assignments[this.state.selectedAssignmentId].jobId;

    try {
      await JobAssignmentApi.updateAdpCode(jobAssignmentId, adpCode);
    } catch (error) {
      this.props.enqueueSnackbar("An error occurred while saving the ADP code.");
    }
  }

  setAssignmentProperty(prop, id = this.state.selectedAssignmentId) {
    this.setState((state) => {
      return {
        assignments: {
          ...state.assignments,
          [id]: {
            ...state.assignments[id],
            ...prop,
          },
        },
      };
    });
  }

  onSave(submit) {
    const billingIsOn = Features.BillingIsOn(this.props.featureFlags);
    const assignment = this.state.assignments[this.state.selectedAssignmentId];
    const isAdjustmentTimecard = assignment.isAdjustment;

    //This copy from the state is because the selectedAssigmentId is updated in generateAutomaticNoteAndSubmitAssignment
    //So setAssignmentProperty can't set correctly saving:false in the same flow.
    const savingAssigmentId = this.state.selectedAssignmentId.slice();

    const requireNote =
      ((assignment.status === TimesheetStatus.RETURNED ||
        assignment.status === TimesheetStatus.REJECTED ||
        (assignment.status === TimesheetStatus.DRAFT && assignment.isDigitalTimecardEntry)) &&
        (assignment.dirtyTimecard || assignment.dirtyTimecardAdjustments)) ||
      assignment.dirtyExpenses ||
      assignment.dtHoursChanged ||
      assignment.dirtyCallOffs;

    if (requireNote && !assignment.note) {
      this.props.enqueueSnackbar("Please add a note before saving.");
      return;
    }

    const noteTypesToCreate = [];

    const createPayrollNote = assignment.dirtyTimecard || assignment.dirtyExpenses;
    const createAdjustmentNote =
      (isAdjustmentTimecard && assignment.note) || assignment.dirtyTimecardAdjustments;

    if (isAdjustmentTimecard && assignment.note) {
      assignment.noteType = NoteType.Adjustment;
    } else if (createPayrollNote && createAdjustmentNote) {
      noteTypesToCreate.push(NoteType.Payroll);
      noteTypesToCreate.push(NoteType.Adjustment);
      assignment.noteTypesToCreate = noteTypesToCreate;
    } else {
      if (assignment.dirtyTimecardAdjustments) {
        assignment.noteType = NoteType.Adjustment;
      } else {
        assignment.noteType = undefined;
      }
    }

    // make sure rate > 0 when hours exist for that line item
    let failedRateHourLineItems = [];
    if (assignment.status === TimesheetStatus.BILLING) {
      failedRateHourLineItems = this.getInvalidRateHourLineItems(assignment.billingEntries);
    }
    if (assignment.status === TimesheetStatus.DRAFT && !assignment.isExternalAgency) {
      failedRateHourLineItems = this.getInvalidRateHourLineItems(assignment.timecardEntries).concat(
        this.getInvalidRateHourLineItems(assignment.nonTaxableExpenses)
      );
    }
    if (failedRateHourLineItems && failedRateHourLineItems.length > 0) {
      this.props.enqueueSnackbar(
        "Rate cannot be zero when Hours are greater than zero (" +
          failedRateHourLineItems.join(", ") +
          ")."
      );
      return;
    }
    this.setAssignmentProperty({ saving: true }, savingAssigmentId);

    let submitStatus = TimesheetStatus.DRAFT;
    if (!submit) {
      submitStatus = assignment.status;
    } else {
      switch (assignment.status) {
        case TimesheetStatus.FOR_REVIEW:
          submitStatus = billingIsOn ? TimesheetStatus.BILLING : TimesheetStatus.APPROVED;
          break;
        case TimesheetStatus.APPROVED:
          submitStatus = TimesheetStatus.FINAL;
          break;
        case TimesheetStatus.DRAFT:
        case TimesheetStatus.REJECTED:
        case TimesheetStatus.RETURNED:
          if (isAdjustmentTimecard) {
            submitStatus = TimesheetStatus.APPROVED;
          } else {
            submitStatus = TimesheetStatus.FOR_REVIEW;
          }
          break;
        default:
          break;
      }
    }

    TimesheetApi.save(assignment, submitStatus)
      .then(async (billData) => {
        const additionalProps = {
          dtHoursChanged: false,
          dirtyTimecard: false,
          dirtyExpenses: false,
          dirtyNote: false,
          dirtyTimecardAdjustments: false,
          note: "",
          notifyTraveler: false,
          notes: billData.updatedNotes,
          noteTypesToCreate: [],
          originalTaxableExpenses: JSON.parse(JSON.stringify(assignment.taxableExpenses)),
          originalNonTaxableExpenses: JSON.parse(JSON.stringify(assignment.nonTaxableExpenses)),
          taxableExpenses: assignment.taxableExpenses.map((e) => {
            return { ...e, proratedSyncBroken: true };
          }),
          nonTaxableExpenses: assignment.nonTaxableExpenses.map((e) => {
            if (e.name === "MissedShiftDeduct") {
              if (e.proratedSyncBroken === true) {
                return { ...e, proratedSyncBroken: true };
              } else {
                return { ...e };
              }
            } else {
              return { ...e, proratedSyncBroken: true };
            }
          }),
          billId: billData.billId,
        };

        if (submit) {
          await this.generateAutomaticNoteAndSubmitAssignment(assignment, additionalProps);
        } else {
          this.setAssignmentProperty(additionalProps);
        }
        this.props.enqueueSnackbar("Changes saved.");
        this.setAssignmentProperty({ saving: false }, savingAssigmentId);

        await this.fetchAssignments();
      })
      .catch((error) => {
        this.props.enqueueSnackbar("An error occurred while saving the timecard information.");
      })
      .finally(() => {
        this.setAssignmentProperty({ saving: false }, savingAssigmentId);
      });
  }

  async generateAutomaticNoteAndSubmitAssignment(assignment, additionalProps) {
    try {
      const assignmentWithNote = assignment;
      assignmentWithNote.note = assignment.isAdjustment
        ? "Submitted from Pending to Approved"
        : "Submitted from Pending to Payroll";

      const response = await TimesheetApi.noteSave(assignmentWithNote, true);
      if (!response.ok) throw response;
      const notes = await response.json();

      additionalProps.notes = notes.updatedNotes;
      this.submitSelectedAssignment(additionalProps);
    } catch (error) {
      console.error(error);
    }
  }

  async onReturn() {
    const assignment = this.state.assignments[this.state.selectedAssignmentId];
    try {
      const result = await PayrollLocksApi.GetPayrollLockStatusByType(
        PayrollLockType.RegularTimecard
      );

      if (result.IsLocked && !assignment.isLate) {
        this.props.enqueueSnackbar(ErrorMessages.LockedPayrollMessage);
        return;
      }
      this.returnTimecardToPending(assignment);
    } catch (e) {
      console.error(e);
    }
  }

  async onReject() {
    const assignment = this.state.assignments[this.state.selectedAssignmentId];
    try {
      const result = await PayrollLocksApi.GetPayrollLockStatusByType(
        PayrollLockType.RegularTimecard
      );
      if (result.IsLocked && !assignment.isLate) {
        this.props.enqueueSnackbar(ErrorMessages.LockedPayrollMessage);
        return;
      }
      this.rejectTimecard(assignment);
    } catch (e) {
      console.error(e);
    }
  }

  async onEditRequest(accepted) {
    const assignment = this.state.assignments[this.state.selectedAssignmentId];
    try {
      const result = await PayrollLocksApi.GetPayrollLockStatusByType(
        PayrollLockType.RegularTimecard
      );
      if (result.IsLocked && !assignment.isLate) {
        this.props.enqueueSnackbar(ErrorMessages.LockedPayrollMessage);
        return;
      }
      this.sendTimecardEdit(assignment, accepted);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * @param {any[]} assignments
   * @param {Object} state
   */
  sortAssignments(assignments, state) {
    //TODO: When sorting becomes a thing, update this to support all types of sorting
    return assignments.sort((a, b) =>
      // eslint-disable-next-line no-nested-ternary
      state.assignments[a].candidateLastName > state.assignments[b].candidateLastName
        ? 1
        : state.assignments[b].candidateLastName > state.assignments[a].candidateLastName
        ? -1
        : 0
    );
  }

  sendTimecardEdit(assignment, accepted) {
    if (!assignment.note) {
      this.props.enqueueSnackbar("Please add a note.");
      return;
    }

    this.setAssignmentProperty({ saving: true });
    if (accepted) {
      TimesheetApi.acceptEditRequest(assignment)
        .then(async (results) => {
          if (!results.ok) throw results;

          await TimesheetApi.noteSave(
            {
              ...assignment,
              note: "This timecard was sent back to draft by accepting the traveler edit request.",
            },
            true
          );

          this.setState((state) => {
            const forReviewAssignments = state.forReviewAssignments.filter((a) => {
              return a !== state.selectedAssignmentId;
            });
            const billingAssignments = state.billingAssignments.filter((a) => {
              return a !== state.selectedAssignmentId;
            });
            const approvedAssignments = state.approvedAssignments.filter((a) => {
              return a !== state.selectedAssignmentId;
            });
            const myAssignments = [state.selectedAssignmentId, ...state.myAssignments];
            const noExpenses = state.noExpenses;

            this.sortAssignments(myAssignments, state);

            return {
              myAssignments: myAssignments,
              forReviewAssignments: forReviewAssignments,
              billingAssignments: billingAssignments,
              approvedAssignments: approvedAssignments,
              lateAssignments: null,
              selectedAssignmentId: this.getNextSelectedAssignment(),
              noExpenses: noExpenses,
              assignments: {
                ...state.assignments,
                [state.selectedAssignmentId]: {
                  ...state.assignments[state.selectedAssignmentId],
                  status: TimesheetStatus.DRAFT,
                  saving: false,
                  dirtyNote: false,
                  note: "",
                  notifyTraveler: false,
                  notes: null,
                },
              },
            };
          });

          this.props.enqueueSnackbar("Timecard was sent back to draft successfully.");
          this.fetchAssignments();
        })
        .catch((error) => {
          this.setAssignmentProperty({ saving: false });
          this.props.enqueueSnackbar("An error occurred while sending the timecard back to draft.");
        });
    } else {
      TimesheetApi.declineEditRequest(assignment)
        .then(async (results) => {
          if (!results.ok) throw results;

          await TimesheetApi.noteSave(
            {
              ...assignment,
              note: "This timecard edit request was declined.",
            },
            true
          );

          this.props.enqueueSnackbar("This timecard edit request was declined successfully.");
          this.setAssignmentProperty({ note: "", notifyTraveler: false }, assignment.id);
          await this.fetchAssignments();
        })
        .catch((error) => {
          this.props.enqueueSnackbar("An error occurred while declining the timecard edit.");
        })
        .finally(() => {
          this.setAssignmentProperty({ saving: false });
        });
    }
  }

  rejectTimecard(assignment) {
    if (!assignment.note) {
      this.props.enqueueSnackbar("Please add a note before rejecting a timecard.");
      return;
    }

    this.setAssignmentProperty({ saving: true });

    TimesheetApi.reject(assignment)
      .then(async (results) => {
        if (!results.ok) throw results;

        this.setState((state) => {
          const forReviewAssignments = state.forReviewAssignments.filter((a) => {
            return a !== state.selectedAssignmentId;
          });
          const billingAssignments = state.billingAssignments.filter((a) => {
            return a !== state.selectedAssignmentId;
          });
          const approvedAssignments = state.approvedAssignments.filter((a) => {
            return a !== state.selectedAssignmentId;
          });
          const myAssignments = [state.selectedAssignmentId, ...state.myAssignments];
          const noExpenses = state.noExpenses;

          this.sortAssignments(myAssignments, state);

          return {
            myAssignments: myAssignments,
            forReviewAssignments: forReviewAssignments,
            billingAssignments: billingAssignments,
            approvedAssignments: approvedAssignments,
            lateAssignments: null,
            selectedAssignmentId: this.getNextSelectedAssignment(),
            noExpenses: noExpenses,
            assignments: {
              ...state.assignments,
              [state.selectedAssignmentId]: {
                ...state.assignments[state.selectedAssignmentId],
                status: TimesheetStatus.REJECTED,
                saving: false,
                dirtyNote: false,
                note: "",
                notifyTraveler: false,
                notes: null,
              },
            },
          };
        });

        this.props.enqueueSnackbar("Timecard was rejected successfully.");
        this.fetchAssignments();
      })
      .catch((error) => {
        this.setAssignmentProperty({ saving: false });
        this.props.enqueueSnackbar("An error occurred while rejecting the timecard.");
      });
  }

  returnTimecardToPending(assignment) {
    if (!assignment.note) {
      this.props.enqueueSnackbar("Please add a note before returning to pending.");
      return;
    }

    this.setAssignmentProperty({ saving: true });

    TimesheetApi.returnToPending(assignment)
      .then((results) => {
        if (!results.ok) throw results;

        this.setState((state) => {
          const forReviewAssignments = state.forReviewAssignments.filter((a) => {
            return a !== state.selectedAssignmentId;
          });
          const billingAssignments = state.billingAssignments.filter((a) => {
            return a !== state.selectedAssignmentId;
          });
          const approvedAssignments = state.approvedAssignments.filter((a) => {
            return a !== state.selectedAssignmentId;
          });
          const myAssignments = [state.selectedAssignmentId, ...state.myAssignments];
          const noExpenses = state.noExpenses;

          this.sortAssignments(myAssignments, state);

          return {
            myAssignments: myAssignments,
            forReviewAssignments: forReviewAssignments,
            billingAssignments: billingAssignments,
            approvedAssignments: approvedAssignments,
            lateAssignments: null,
            selectedAssignmentId: this.getNextSelectedAssignment(),
            noExpenses: noExpenses,
            assignments: {
              ...state.assignments,
              [state.selectedAssignmentId]: {
                ...state.assignments[state.selectedAssignmentId],
                status: TimesheetStatus.RETURNED,
                saving: false,
                dirtyNote: false,
                note: "",
                notifyTraveler: false,
                notes: null,
              },
            },
          };
        });

        this.props.enqueueSnackbar("Changes saved.");
        this.fetchAssignments();
      })
      .catch((error) => {
        this.setAssignmentProperty({ saving: false });
        this.props.enqueueSnackbar("An error occurred while returning the timecard to pending.");
      });
  }

  invalidMileage(assignment) {
    if (assignment && assignment.billingEntries) {
      const mileage = assignment.billingEntries.find((x) => x.name === "MileageReimbursement");
      return mileage
        ? (mileage.hours !== 0 && mileage.rate === 0) || (mileage.hours === 0 && mileage.rate !== 0)
        : false;
    }
    return false;
  }

  getInvalidRateHourLineItems(entries) {
    // check for any hour/rate pairs where hours exist, but rate does not
    const checkFor = [
      "RegularHours",
      "OnCall",
      "CallBack",
      "Overtime8",
      "DoubleTime",
      "Overtime48",
      "OvertimeOther",
      "ModulePay",
      "Holiday",
      "Guaranteed",
      "PreceptCharge",
      "ShiftDifferential",
      "MileageReimbursement",
    ];

    const failedLineItems = [];
    if (entries) {
      for (var counter = 0; counter < checkFor.length; counter++) {
        const lineItem = entries.find((x) => x.name === checkFor[counter]);
        if (lineItem) {
          if (checkFor[counter] === "MileageReimbursement") {
            if (lineItem.amount && !lineItem.paid) {
              failedLineItems.push("'" + lineItem.title + "'");
            }
          }
          if (lineItem.hours && !lineItem.rate) {
            failedLineItems.push("'" + lineItem.title + "'");
          }
        }
      }
      return failedLineItems;
    }
  }

  onApprove() {
    const assignment = this.state.assignments[this.state.selectedAssignmentId];

    if (this.invalidMileage(assignment)) {
      this.props.enqueueSnackbar("Milage reimbursement rate or miles cannot be zero.");
      return;
    }
    // make sure rate > 0 when hours exist for that line item
    let failedRateHourLineItems = [];
    if (assignment.status === TimesheetStatus.BILLING) {
      failedRateHourLineItems = this.getInvalidRateHourLineItems(assignment.billingEntries);
    }
    if (assignment.status === TimesheetStatus.DRAFT && !assignment.isExternalAgency) {
      failedRateHourLineItems = this.getInvalidRateHourLineItems(assignment.timecardEntries);
    }
    if (failedRateHourLineItems && failedRateHourLineItems.length > 0) {
      this.props.enqueueSnackbar(
        "Rate cannot be zero when Hours are greater than zero (" +
          failedRateHourLineItems.join(", ") +
          ")."
      );
      return;
    }

    this.setAssignmentProperty({ saving: true });

    TimesheetApi.approve(assignment)
      .then((results) => {
        if (!results.ok) {
          throw results;
        }

        this.setState((state) => {
          const forReviewAssignments = state.forReviewAssignments.filter((a) => {
            return a !== state.selectedAssignmentId;
          });
          const billingAssignments = state.billingAssignments.filter((a) => {
            return a !== state.selectedAssignmentId;
          });
          const approvedAssignments = [state.selectedAssignmentId, ...state.approvedAssignments];
          const myAssignments = state.myAssignments.filter((a) => {
            return a !== state.selectedAssignmentId;
          });

          this.sortAssignments(myAssignments, state);

          return {
            myAssignments: myAssignments,
            forReviewAssignments: forReviewAssignments,
            billingAssignments: billingAssignments,
            approvedAssignments: approvedAssignments,
            lateAssignments: null,
            selectedAssignmentId: this.getNextSelectedAssignment(),
            assignments: {
              ...state.assignments,
              [state.selectedAssignmentId]: {
                ...state.assignments[state.selectedAssignmentId],
                status: TimesheetStatus.APPROVED,
                locked: assignment.status === TimesheetStatus.BILLING,
                saving: false,
                dirtyNote: false,
                dirtyBillingEntries: false,
                note: "",
                notifyTraveler: false,
                notes: null,
              },
            },
          };
        });

        this.props.enqueueSnackbar("Approved.");

        this.fetchAssignments();
      })
      .catch((error) => {
        this.setAssignmentProperty({ saving: false });
        this.props.enqueueSnackbar("An error occurred while saving the timecard information.");
      });
  }

  onBilling() {
    const assignment = this.state.assignments[this.state.selectedAssignmentId];
    this.setAssignmentProperty({ saving: true });

    let messageBody = "";
    if (assignment.note) {
      messageBody = JSON.stringify({
        TimeSheetId: assignment.id,
        Contents: assignment.note,
        NoteType: NoteType.Recruiter,
        NotifyToTraveler: assignment.notifyTraveler,
      });
    } else {
      messageBody = JSON.stringify({
        TimeSheetId: assignment.id,
      });
    }

    TimesheetApi.billing(assignment.id, messageBody)
      .then((results) => {
        if (!results.ok) {
          throw results;
        }

        this.setState((state) => {
          const forReviewAssignments = state.forReviewAssignments.filter((a) => {
            return a !== state.selectedAssignmentId;
          });
          const approvedAssignments = state.approvedAssignments.filter((a) => {
            return a !== state.selectedAssignmentId;
          });
          const billingAssignments = [state.selectedAssignmentId, ...state.billingAssignments];
          const myAssignments = state.myAssignments.filter((a) => {
            return a !== state.selectedAssignmentId;
          });

          this.sortAssignments(myAssignments, state);

          return {
            myAssignments: myAssignments,
            forReviewAssignments: forReviewAssignments,
            billingAssignments: billingAssignments,
            approvedAssignments: approvedAssignments,
            lateAssignments: null,
            selectedAssignmentId: this.getNextSelectedAssignment(),
            assignments: {
              ...state.assignments,
              [state.selectedAssignmentId]: {
                ...state.assignments[state.selectedAssignmentId],
                status: TimesheetStatus.BILLING,
                saving: false,
                dirtyNote: false,
                note: "",
                notifyTraveler: false,
                notes: null,
              },
            },
          };
        });

        this.props.enqueueSnackbar("Moved to Billing.");
        this.fetchAssignments();
      })
      .catch((error) => {
        this.setAssignmentProperty({ saving: false });
        this.props.enqueueSnackbar("An error occurred while saving the timecard information.");
      });
  }

  onNotesRetrieved(assignment, notes) {
    this.setAssignmentProperty({ notes: notes });
  }

  onNoteChange(assignment, note) {
    this.setAssignmentProperty({ note: note, dirtyNote: !!note });
  }

  /**
   * @param {boolean} notifyTraveler
   */
  onNotifyTravelerChange(assignment, notifyTraveler) {
    this.setAssignmentProperty({ notifyTraveler });
  }

  onNoteSave(assignment, showSuccessSnackbar = true) {
    this.setAssignmentProperty({ savingNote: true }, assignment.id);

    TimesheetApi.noteSave(assignment)
      .then((results) => {
        if (!results.ok) throw results;

        if (showSuccessSnackbar) {
          this.props.enqueueSnackbar("Note saved.");
        }

        /* Sending null so that the component refreshes the notes */
        /* Ideally this should refresh the notes and handle it in memory but we are missing the user info to create the note */
        this.setAssignmentProperty(
          { savingNote: false, dirtyNote: false, notes: null, note: "", notifyTraveler: false },
          assignment.id
        );
      })
      .catch((error) => {
        this.setAssignmentProperty({ savingNote: false }, assignment.id);
        this.props.enqueueSnackbar("An error occurred while saving the note.");
      });
  }

  onSaveApproved() {
    const assignment = this.state.assignments[this.state.selectedAssignmentId];

    const noteTypesToCreate = [];
    if (assignment.dirtyTimecard || assignment.dirtyExpenses) {
      assignment.noteType = NoteType.Payroll;
      noteTypesToCreate.push(NoteType.Payroll);
    }

    if (assignment.dirtyBillingEntries) {
      assignment.noteType = NoteType.Billing;
      noteTypesToCreate.push(NoteType.Billing);
    }

    if (assignment.dirtyTimecardAdjustments) {
      assignment.noteType = NoteType.Adjustment;
      noteTypesToCreate.push(NoteType.Adjustment);
    }

    assignment.noteTypesToCreate = noteTypesToCreate;

    const requireNote =
      (assignment.status === TimesheetStatus.APPROVED &&
        (assignment.dirtyTimecard || assignment.dirtyTimecardAdjustments)) ||
      assignment.dirtyExpenses ||
      assignment.dirtyBillingEntries ||
      assignment.dirtyCallOffs;

    if (requireNote && !assignment.note) {
      this.props.enqueueSnackbar("Please add a note before saving.");
      return;
    }
    if (this.invalidMileage(assignment)) {
      this.props.enqueueSnackbar("Milage reimbursement rate or miles cannot be zero.");
      return;
    }
    // make sure rate > 0 when hours exist for that line item
    if (!assignment.isExternalAgency) {
      const failedRateHourLineItems = assignment.isAdjustment
        ? this.getInvalidRateHourLineItems(assignment.timecardEntries)
        : this.getInvalidRateHourLineItems(assignment.timecardEntries).concat(
            this.getInvalidRateHourLineItems(assignment.billingEntries)
          );
      if (failedRateHourLineItems && failedRateHourLineItems.length > 0) {
        this.props.enqueueSnackbar(
          "Rate cannot be zero when Hours are greater than zero (" +
            failedRateHourLineItems.join(", ") +
            ")."
        );
        return;
      }
    }

    this.setAssignmentProperty({ saving: true });

    TimesheetApi.save(assignment, TimesheetStatus.APPROVED)
      .then((results) => {
        const stateProps = {
          saving: false,
          dirtyTimecard: false,
          dirtyTimecardAdjustments: false,
          dirtyExpenses: false,
          savingNote: false,
          dirtyNote: false,
          dirtyBillingEntries: false,
          noteTypesToCreate: [],
          notes: results.updatedNotes,
          note: "",
          notifyTraveler: false,
        };

        this.setAssignmentProperty(stateProps, assignment.id);

        this.props.enqueueSnackbar("Changes saved.");
        this.fetchAssignments();
      })
      .catch((error) => {
        this.setAssignmentProperty({ saving: false });
        this.props.enqueueSnackbar("An error occurred while saving the timecard information.");
      });
  }

  openCreateTimecardDialog = async () => {
    try {
      const result = await PayrollLocksApi.GetPayrollLockStatusByType(
        PayrollLockType.RegularTimecard
      );
      if (result.IsLocked) {
        this.props.enqueueSnackbar(ErrorMessages.LockedPayrollMessage);
        return;
      }
      this.setState({ createTimecardDialogOpen: true, isAdjustment: false });
    } catch (e) {
      console.error(e);
    }
  };

  openCreateTimecardAdjusmentDialog = async () => {
    try {
      const result = await PayrollLocksApi.GetPayrollLockStatusByType(
        PayrollLockType.RegularTimecard
      );
      if (!result.IsLocked) {
        this.props.enqueueSnackbar(ErrorMessages.NotLockedPayrollMessage);
        return;
      }
      this.setState({ createTimecardDialogOpen: true, isAdjustment: true });
    } catch (e) {
      console.error(e);
    }
  };

  validateLockRecruiterPayroll = (goToTimesheet, id) => {
    if (goToTimesheet) {
      this.closeCreateTimecardDialog(id);
    } else {
      this.setState({ createTimecardDialogOpen: false });
    }
  };

  closeCreateTimecardDialog = (id) => {
    if (this.state.assignments[id]) {
      this.setState({
        createTimecardDialogOpen: false,
        ...this.goToTimesheet(id, this.state.assignments, this.state.staffUserId),
      });
    } else {
      this.setState({
        myAssignments: null,
        forReviewAssignments: null,
        billingAssignments: null,
        approvedAssignments: null,
        lateAssignments: null,
        allAssignments: null,
        assignments: null,
        createTimecardDialogOpen: false,
        selectedAssignmentId: null,
      });
      this.fetchAssignments(id);
    }
  };

  onTimecardDelete = (jobAssignment) => {
    TimesheetApi.deleteTimecard(jobAssignment.id)
      .then((results) => {
        if (!results.ok) throw results;

        this.props.enqueueSnackbar("Timecard Deleted.");

        this.setState({
          myAssignments: null,
          forReviewAssignments: null,
          billingAssignments: null,
          approvedAssignments: null,
          lateAssignments: null,
          allAssignments: null,
          assignments: null,
          createTimecardDialogOpen: false,
          selectedAssignmentId: null,
        });

        this.fetchAssignments();
      })
      .catch((error) => {
        this.props.enqueueSnackbar("An error occurred while deleting the Timecard.");
      });
  };

  onLateTimecard = (jobAssignment) => {
    TimesheetApi.late(jobAssignment.id)
      .then((results) => {
        if (!results.ok) throw results;

        this.props.enqueueSnackbar("Timecard status updated to late successfully.");

        this.setState({
          myAssignments: null,
          forReviewAssignments: null,
          billingAssignments: null,
          approvedAssignments: null,
          lateAssignments: null,
          allAssignments: null,
          assignments: null,
          createTimecardDialogOpen: false,
          selectedAssignmentId: null,
        });

        this.fetchAssignments();
      })
      .catch((error) => {
        this.props.enqueueSnackbar("An error occurred while updating the Timecard's status.");
      });
  };

  getActivePeriodDates = () => {
    // ToDO: Change the last parameter (1) to the current date in proper format (YYYYmmDD).
    TimesheetApi.activePeriod(1).then((data) => {
      const fdYear = data.item1.slice(0, 4);
      const fdMonth = data.item1.slice(5, 7);
      const fdDay = data.item1.slice(8, 10);
      const ldYear = data.item2.slice(0, 4);
      const ldMonth = data.item2.slice(5, 7);
      const ldDay = data.item2.slice(8, 10);

      this.setState({
        firstDate: `${fdMonth}/${fdDay}/${fdYear}`,
        lastDate: `${ldMonth}/${ldDay}/${ldYear}`,
      });
    });
  };

  getTimecards = (assignmentsList) => {
    return assignmentsList == null || this.state.assignments == null ? (
      <ListLoading />
    ) : (
      <Timecards
        selectedAssignmentId={this.state.selectedAssignmentId}
        onTimecardSelected={this.updateSelectedAssignment}
        assignments={assignmentsList.map((a) => this.state.assignments[a])}
        emptyAssignmentsMessage={"All done here!"}
        scrollIntoId={this.state.scrollIntoId}
        fetchAssignments={this.fetchAssignments}
      />
    );
  };

  getApprovedList = (approvedAssignments, lateAssignments) => {
    let assigments = [];
    if (approvedAssignments != null && lateAssignments != null) {
      assigments = approvedAssignments.concat(lateAssignments);
    }
    return assigments;
  };

  getAssignmentsByState = (state) => {
    return this.getTimecards(state);
  };

  render() {
    const billingIsOn = Features.BillingIsOn(this.props.featureFlags);
    const isRecruiter = LoggedUser.isRecruiter();
    const isRecruiterTeamLead = LoggedUser.isRecruiterTeamLead();
    const isAssistant = LoggedUser.isAssistant();
    const isAdmin = LoggedUser.isAdmin();
    const isSuperAdmin = LoggedUser.isSuperAdmin();
    const tabsContent =
      this.state.assignments == null ? (
        ""
      ) : (
        <HomeTabs
          state={this.state}
          billingIsOn={billingIsOn}
          setNewTabIndex={(idx) => {
            this.setState({ selectedTabIndex: idx });
          }}
        />
      );
    const tabsPanels = (
      <>
        <TabPanel value={this.state.selectedTabIndex} index={QueueTabs.PENDING}>
          {this.state.selectedTabIndex === QueueTabs.PENDING
            ? this.getAssignmentsByState(this.state.myAssignments)
            : []}
        </TabPanel>
        <TabPanel value={this.state.selectedTabIndex} index={QueueTabs.FOR_REVIEW}>
          {this.state.selectedTabIndex === QueueTabs.FOR_REVIEW
            ? this.getAssignmentsByState(this.state.forReviewAssignments)
            : []}
        </TabPanel>
        {billingIsOn && (
          <TabPanel value={this.state.selectedTabIndex} index={QueueTabs.BILLING}>
            {this.state.selectedTabIndex === QueueTabs.BILLING
              ? this.getAssignmentsByState(this.state.billingAssignments)
              : []}
          </TabPanel>
        )}
        <TabPanel
          value={this.state.selectedTabIndex}
          index={QueueTabs.APPROVED + (billingIsOn ? 0 : -1)}>
          {this.state.selectedTabIndex === QueueTabs.APPROVED
            ? this.getAssignmentsByState(this.state.approvedAssignments)
            : []}
        </TabPanel>
        <TabPanel
          value={this.state.selectedTabIndex}
          index={QueueTabs.ALL_TRAVELERS + (billingIsOn ? 0 : -1)}>
          {isRecruiter || this.isSalesRep || isAssistant
            ? null
            : this.state.selectedTabIndex === QueueTabs.ALL_TRAVELERS
            ? this.getAssignmentsByState(this.state.allAssignments)
            : []}
        </TabPanel>
      </>
    );

    return (
      <>
        <div style={{ display: "flex", overflowY: "hidden" }}>
          <div className="timecards">
            <div className="timecards-header">
              <div className="timecards-header-payperiod">
                <h1 className="timecards-home-title">
                  PAY PERIOD:{" "}
                  <span className="timecards-home-title-date">{this.state.firstDate}</span> to{" "}
                  <span className="timecards-home-title-date">{this.state.lastDate}</span>
                </h1>
                <Typography className="timecards-pay-period"></Typography>
              </div>
              <div className="timecards-header-actions">
                {(isAdmin || isSuperAdmin) && (
                  <TimercardAddButton
                    viewingSalesRep={this.state.isSalesRepSelected}
                    openCreateTimecardDialog={this.openCreateTimecardDialog}
                    openCreateTimecardAdjusmentDialog={
                      this.openCreateTimecardAdjusmentDialog
                    }></TimercardAddButton>
                )}
                {(isRecruiter || isRecruiterTeamLead || isAssistant || this.isSalesRep) && (
                  <>
                    <Button
                      onClick={() => {
                        downloadPayrollReport(this.state.currentRecruiterId, false);
                      }}>
                      Payroll Report
                    </Button>
                    <Button
                      onClick={() => {
                        downloadBillingReport(this.state.currentRecruiterId, false);
                      }}>
                      Billing Report
                    </Button>
                  </>
                )}
              </div>
            </div>
            {tabsContent}
            {tabsPanels}
          </div>
          <div className="timecard-detail-container">
            {this.state.selectedAssignmentId &&
              this.state.assignments &&
              this.state.assignments[this.state.selectedAssignmentId] && (
                <TimecardDetail
                  jobAssignment={this.state.assignments[this.state.selectedAssignmentId]}
                  onNotWorkedAndNoExpenses={this.onNotWorkedAndNoExpenses}
                  onConvertExpenseTimecard={this.onConvertExpenseTimecard}
                  onTimecardFileUploaded={this.onTimecardFileUploaded}
                  onConfirmationPageFileUploaded={this.onConfirmationPageFileUploaded}
                  onTimecardUploadDelete={this.onTimecardUploadDelete}
                  onFacilityReportFileUploaded={this.onFacilityReportFileUploaded}
                  onFacilityReportFilesAdded={this.onFacilityReportFilesAdded}
                  onTimecardEntriesChange={this.onTimecardEntriesChange}
                  onTimecardAdjustmentEntriesChange={this.onTimecardAdjustmentEntriesChange}
                  onBillingEntriesChange={this.onBillingEntriesChange}
                  onTaxableExpensesChange={this.onTaxableExpensesChange}
                  onBonusExpensesChange={this.onBonusExpensesChange}
                  onNonTaxableExpensesChange={this.onNonTaxableExpensesChange}
                  onAdpCodeUpdated={this.onAdpCodeUpdated}
                  onSave={this.onSave}
                  onSaveApproved={this.onSaveApproved}
                  onBilling={this.onBilling}
                  onReturn={this.onReturn}
                  onReject={this.onReject}
                  onEditRequest={this.onEditRequest}
                  onApprove={this.onApprove}
                  onNotesRetrieved={this.onNotesRetrieved}
                  onNoteChange={this.onNoteChange}
                  onNotifyTravelerChange={this.onNotifyTravelerChange}
                  onNoteSave={this.onNoteSave}
                  onTimecardDetailFetched={this.onTimecardDetailFetched}
                  featureFlags={this.props.featureFlags}
                  onTimecardDelete={this.onTimecardDelete}
                  onLateTimecard={this.onLateTimecard}
                  firstDate={this.state.firstDate}
                  lastDate={this.state.lastDate}
                  onDidNotWorkReset={this.onDidNotWorkReset}
                  onRateChangesAcknowledged={this.onRateChangesAcknowledged}
                  onNonTaxableAdjustmentsChange={this.onNonTaxableAdjustmentsChange}
                  fetchAssignments={this.fetchAssignments}
                  onMessagesRead={this.onMessagesRead}
                  onCallOffsChange={this.onTimesheetCallOffChange}
                />
              )}
          </div>
        </div>
        <NewTimecardDialog
          open={this.state.createTimecardDialogOpen}
          onClose={this.validateLockRecruiterPayroll}
          isAdjustment={this.state.isAdjustment}
          currentRecruiterId={this.state.currentRecruiterId}
        />
      </>
    );
  }
}

function ListLoading() {
  return (
    <div className="timecard-list-loading-container">
      <CircularProgress />
    </div>
  );
}

Home.propTypes = {
  featureFlags: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => {
  return {
    titleChanged: () => dispatch({ type: TITLE_CHANGED, title: "Profile" }),
  };
};

export default connect(null, mapDispatchToProps)(withSnackbar(Home));
