import React, { useState } from "react";
import { Box, IconButton, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import NumberFormat from "react-number-format";
import { useSnackbar } from "notistack";

type AdpCodeInputProps = {
  jobAssignment: any;
  onAdpCodeUpdated: (adpCode: string | null) => void;
  readonly?: boolean;
};

const AdpCodeInput = ({ jobAssignment, onAdpCodeUpdated, readonly = false }: AdpCodeInputProps) => {
  const snackbar = useSnackbar();
  const [showInput, setShowInput] = useState(false);

  const onAdpCodeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue === "") {
      onAdpCodeUpdated(null);
      setShowInput(false);
      return;
    }

    const adpCode = parseInt(inputValue);

    if (isNaN(adpCode)) {
      onAdpCodeUpdated(jobAssignment.adpCode);
      snackbar.enqueueSnackbar("Please enter a valid number", {
        variant: "error",
      });
      return;
    }

    if (adpCode < 100000 || adpCode > 999999) {
      snackbar.enqueueSnackbar("Please enter a valid 6-digit number", {
        variant: "error",
      });
      onAdpCodeUpdated(jobAssignment.adpCode);
      return;
    }

    onAdpCodeUpdated(inputValue);
    setShowInput(false);
  };

  const onEditClick = () => {
    setShowInput(true);
  };

  return (
    <Box
      className="timecard-detail-topbar-details-ssn"
      display={"flex"}
      alignItems={"flex-end"}
      sx={{ m: 0 }}
      gap={0.5}>
      <div>
        <span> - ADP Code: </span>
        {!showInput && (
          <span>{jobAssignment.adpCode ? <b>{jobAssignment.adpCode}</b> : "not set"}</span>
        )}
      </div>
      {!showInput ? (
        !readonly && (
          <IconButton aria-label="delete" size="small" onClick={onEditClick} sx={{ p: 0 }}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        )
      ) : (
        <NumberFormat
          customInput={TextField}
          autoFocus
          id="adpCode"
          size="small"
          autoComplete="off"
          sx={{
            maxWidth: 50,
            "& #adpCode": { fontSize: 12, pb: 0 },
          }}
          onBlur={onAdpCodeBlur}
          format="######"
          value={jobAssignment.adpCode ?? ""}></NumberFormat>
      )}
    </Box>
  );
};

export default AdpCodeInput;
