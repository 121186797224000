export const QueueTabs = Object.freeze({
  PENDING: 0,
  FOR_REVIEW: 1,
  BILLING: 2,
  APPROVED: 3,
  ALL_TRAVELERS: 4,
});

export const TimesheetFileTypes = Object.freeze({
  NONE: 0x0,
  TIMECARD: 0x1,
  FACILITY_REPORT: 0x2,
});

export const JobAssignmentFileTypes = Object.freeze({
  NONE: 0x0,
  CONFIRMATION_PAGE: 0x1,
});

export const TimesheetStatus = Object.freeze({
  DRAFT: "Draft",
  FOR_REVIEW: "ForReview",
  BILLING: "Billing",
  RETURNED: "Returned",
  FINAL: "Final",
  APPROVED: "Approved",
  LATE: "Late",
  NOTIMECARD: "NoTimecard",
  REJECTED: "Rejected",
});

export enum TimesheetSubStatus {
  DidNotWork = 0,
  DidNotSubmit = 1,
}

export enum TimesheetSubStatusLabel {
  DidNotWork = "DidNotWork",
  DidNotSubmit = "DidNotSubmit",
}
// Actual map from BE
export const TimesheetSubStatusMap = Object.freeze({
  0: "Did Not Work",
  1: "Did Not Submit",
});

export const JobAssignmentStatus = Object.freeze({
  COMPLETED: "Completed",
  IN_PROGRESS: "In Progress",
  EARLY_TERM: "Early Term",
  CANCELLED: "Cancelled",
});

export const Roles = Object.freeze({
  SuperAdmin: "SuperAdmin",
  Admin: "Admin",
  Recruiter: "Recruiter",
  User: "User",
  SalesRep: "SalesRep",
  RecruiterTeamLead: "RecruiterTeamLead",
  Assistant: "RecruiterAssistant",
  PayPeriodUnlocker: "PayPeriodUnlocker",
  TriagePlusSuperAdmin: "Triage+.SuperAdmin",
  TriagePlusPayrollUnlocker: "Triage+.PayPeriodUnlocker",
});

export const ExpenseStatus = Object.freeze({
  DRAFT: "Draft",
  IN_REVIEW: "In Review",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  PAID: "Paid",
  TO_BE_PAID: "To be Paid",
});

export const NoteType = Object.freeze({
  Payroll: "PayrollNote",
  Billing: "BillingNote",
  Expenses: "ExpenseNote",
  Recruiter: "RecruiterNote",
  Adjustment: "AdjustmentNote",
  Assignment: "AssignmentNote",
});

export const NoteSource = Object.freeze({
  Payroll: "Payroll",
  Billing: "Billing",
  Expenses: "Expense",
  Adjustment: "Adjustment",
});

export const RoleCodes = Object.freeze({
  SuperAdmin: 2,
  Admin: 4,
  Recruiter: 3,
  SalesRep: 5,
  RecruiterTeamLead: 6,
});

export const TimecardPanels = Object.freeze({
  Timecard: "panel1",
  NonTaxable: "panel2",
  Taxable: "panel3",
  Bonus: "panel4",
  Expenses: "panel5",
  CallOffHours: "panel6",
});

export const ErrorMessages = Object.freeze({
  LockedPayrollMessage:
    "Payroll has been locked, contact the payroll department for additional questions",
  DuplicatedOrInvalidTimecarAdjustment:
    "The Adjustment timecard already exist for this period or is invalid",
  NotLockedPayrollMessage:
    "Payroll is not locked yet, contact the payroll department for additional questions",
});

export const ExpenseExceptionMessages = Object.freeze({
  JobCancelledMessage: "Job assignment has been cancelled",
  PayDateMessage: "Pay date passed, expense is not associated with any timecard",
});

export const FileFordwaredSatus = Object.freeze({
  Disabled: "Disabled",
  Pending: "Pending",
  Late: "Late",
});

export const PayrollLockType = Object.freeze({
  RegularTimecard: 1,
  LateAndAdjustmentTimecard: 2,
});

export const Tenant = Object.freeze({
  All: 0,
  Triage: 1,
  TaleMed: 2,
});

export const NetSuiteLockState = Object.freeze({
  None: 0,
  TimesheetsSucceded: 1,
  RegularTimesheetsFailed: 2,
  LateTimesheetsFailed: 3,
});

export const TimecardUploadSource = Object.freeze({
  AutoAttaching: 0,
  DigitalTimecards: 1,
  Manual: 2,
  Spreadsheet: 3,
  Unknown: 4,
});

export const WorkWeek = Object.freeze({
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
});

export enum ReferralStatus {
  InProgress = "In Progress",
  ToBePaid = "To Be Paid",
  Paid = "Paid",
  Cancelled = "Cancelled",
}

export enum ReferralsDownloadType {
  DownloadAll = "all",
  DownloadSelected = "selected",
}

export enum ReferralsPaymentType {
  Ukg = "Ukg",
  NetSuite = "NetSuite",
}

export enum NotesUserType {
  All = "All",
  System = "System",
  Internal = "Internal",
  Traveler = "Traveler",
}

export enum ReferrerPaperworkStatus {
  NotSent = "Not Sent",
  PendingSignature = "Pending Signature",
  Signed = "Signed",
}

export enum MessageSenderRole {
  Administrator = 2,
  Application = 1,
  Traveler = 0,
}

export enum SignatureStatus {
  NotRequired = 0,
  PendingSignature = 1,
  Signed = 2,
  Declined = 3,
  MarkAsCompletedBpp = 4,
}
