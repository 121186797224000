import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Button, ButtonGroup } from "@mui/material";
import { withSnackbar } from "notistack";
import { CircularProgress, Menu, MenuItem, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getMvpUsersApi } from "../../api/UsersApi";
import BillingApi from "../../api/BillingApi";
import {
  downloadPayrollReport,
  downloadBillingReport,
  downloadAdpReport,
} from "../shared/functions/ReportDownload";
import { lockTimecardsPayrollReport } from "../shared/functions/LockTimecards";
import MaterialTable from "material-table";
import { RoleCodes, PayrollLockType, NetSuiteLockState } from "../../commons/Enums";
import LockIcon from "@mui/icons-material/Lock";
import SettingsIcon from "@mui/icons-material/Settings";
import LockPayrollDialog from "../shared/components/LockPayrollDialog";
import { downloadUltiProDataExport } from "./../shared/functions/DownloadDataExport";
import CanRenderChildren from "../shared/functions/CanRenderChildren";
import {
  PROFILE_TABLE_CHANGED,
  PROFILE_TABLE_SWITCH_NETSUITE_CHANGED,
  TITLE_CHANGED,
} from "../../redux/actions/actionTypes";
import { connect, useDispatch, useSelector } from "react-redux";
import ProfileToolbar from "./components/ProfileToolbar";
import PayrollLocksApi from "../../api/PayrollLocksApi";
import TriagePlusReportDownloadDialog from "./components/TriagePlusReportDownloadDialog";
import { LoadingButton } from "shared/loadingButton/LoadingButton";

const Profile = (props) => {
  const snackbar = useSnackbar();

  const [mvpusers, setMvpusers] = useState(null);
  const [timesheetStart, setTimesheetStart] = useState(null);
  const [timesheetEnd, setTimesheetEnd] = useState(null);
  const [isLockPayrollDialogOpen, setLockPayrollDialogOpen] = React.useState(false);
  const [lateTimecardsReleased, setLateTimecardsReleased] = React.useState(false);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [loading, setLoading] = useState(new Map());
  const [anchorEl, setAnchorEl] = useState(null);
  const profileState = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const [lockType, setlockType] = useState(PayrollLockType.RegularTimecard);
  const [isTriagePlusReportDowloadDialogOpen, setIsTriagePlusReportDowloadDialogOpen] =
    useState(false);
  const [isLoadingTriagePlusReportDowload, setIsLoadingTriagePlusReportDowload] = useState(false);

  const profileTableRef = useRef(props.profile ?? null);

  const handleTableChange = () => {
    props.profileChanged({ ...profileTableRef.current });
  };

  /**
   * @param {Event} event
   * @param {string} id
   */
  const openMenu = (event, id) => {
    setAnchorEl({ [id]: event.currentTarget });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const lookupRoles = {};

  lookupRoles[RoleCodes.Recruiter] = "Recruiter";
  lookupRoles[RoleCodes.SalesRep] = "Sales Representative";
  lookupRoles[RoleCodes.RecruiterTeamLead] = "Recruiting Team Lead";

  const openPayrollDialog = (lType) => {
    setlockType(lType);
    setLockPayrollDialogOpen(true);
  };

  const closePayrollDialog = () => {
    setLockPayrollDialogOpen(false);
  };

  const noFinalReport = async () => {
    await downloadUltiProDataExport(lockType === PayrollLockType.RegularTimecard ? false : true);
  };

  const lockAndDownloadPayrollRawDataReport = async () => {
    await lockTimecardsPayrollReport(lockType, (msg) => snackbar.enqueueSnackbar(msg));
    await downloadUltiProDataExport(lockType === PayrollLockType.RegularTimecard ? false : true);
  };

  const countSortFactory = (property) => {
    if (lateTimecardsReleased) {
      return (row1, row2) => row1[property].total - row2[property].total;
    }

    return (row1, row2) => row1[property].notLate - row2[property].notLate;
  };

  const countRender = lateTimecardsReleased ? (count) => count.total : (count) => count.notLate;

  const countSearchFactory = (property) => {
    if (lateTimecardsReleased) {
      return (terms, rowData) => !terms || terms == rowData[property].total;
    }

    return (terms, rowData) => !terms || terms == rowData[property].notLate;
  };

  const filterMaxUploadAttempts = () => {
    return mvpusers.filter(
      (item) =>
        item.netSuiteLockState === NetSuiteLockState.RegularTimesheetsFailed ||
        item.netSuiteLockState === NetSuiteLockState.LateTimesheetsFailed
    );
  };

  const lockColor = (lockState) => {
    switch (lockState) {
      case NetSuiteLockState.LateTimesheetsFailed:
        return "#ffe108";
      case NetSuiteLockState.RegularTimesheetsFailed:
        return "#fa0000";
      default:
        return "black";
    }
  };

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Profile" });
  }, []);

  useEffect(() => {
    if (mvpusers && profileState?.switchState) {
      setFilteredUsers(filterMaxUploadAttempts());
    } else {
      setFilteredUsers(mvpusers);
    }
  }, [profileState?.switchState]);

  const loadColumns = () => {
    const order = profileTableRef.current?.state?.orderDirection;
    const columns = [
      {
        title: "Name",
        field: "fullName",
        render: (row) => (
          <div
            style={{
              display: "flex",
              minHeight: "100%",
              justifyContent: "space-between",
              maxWidth: "125px",
              alignItems: "center",
            }}>
            <Link to={{ pathname: `/home/${row.id}/${row.userType == RoleCodes.SalesRep}` }}>
              {row.fullName}
            </Link>
            {row.netSuiteLockState !== NetSuiteLockState.None ? (
              <Tooltip title={row.netSuiteLockText} placement="top">
                <LockIcon
                  sx={{
                    color: lockColor(row.netSuiteLockState),
                    verticalAlign: "middle",
                    marginLeft: ".25em",
                  }}
                />
              </Tooltip>
            ) : null}
          </div>
        ),
        defaultFilter:
          profileTableRef.current?.state?.columns?.filter((x) => x.field == "fullName")[0].tableData
            .filterValue ?? "",
      },
      {
        title: "Role",
        field: "userType",
        lookup: lookupRoles,
        defaultFilter:
          profileTableRef.current?.state?.columns?.filter((x) => x.field == "userType")[0].tableData
            .filterValue ?? "",
      },
      {
        title: "Pending",
        field: "pendingCount",
        customSort: countSortFactory("pendingCount"),
        customFilterAndSearch: countSearchFactory("pendingCount"),
        render: (row) => countRender(row.pendingCount),
        defaultFilter:
          profileTableRef.current?.state?.columns?.filter((x) => x.field == "pendingCount")[0]
            .tableData.filterValue ?? "",
      },
      {
        title: "Payroll",
        field: "forReviewCount",
        customSort: countSortFactory("forReviewCount"),
        customFilterAndSearch: countSearchFactory("forReviewCount"),
        render: (row) => countRender(row.forReviewCount),
        defaultFilter:
          profileTableRef.current?.state?.columns?.filter((x) => x.field == "forReviewCount")[0]
            .tableData.filterValue ?? "",
      },
      {
        title: "Billing",
        field: "billingCount",
        customSort: countSortFactory("billingCount"),
        customFilterAndSearch: countSearchFactory("billingCount"),
        render: (row) => countRender(row.billingCount),
        defaultFilter:
          profileTableRef.current?.state?.columns?.filter((x) => x.field == "billingCount")[0]
            .tableData.filterValue ?? "",
      },
      {
        title: "Approved",
        field: "approvedCount",
        customSort: countSortFactory("approvedCount"),
        customFilterAndSearch: countSearchFactory("approvedCount"),
        render: (row) => countRender(row.approvedCount),
        defaultFilter:
          profileTableRef.current?.state?.columns?.filter((x) => x.field == "approvedCount")[0]
            .tableData.filterValue ?? "",
      },
    ];
    if (!lateTimecardsReleased) {
      columns.push({
        title: "Late",
        field: "lateCount",
        defaultFilter:
          profileTableRef.current?.state?.columns?.filter((x) => x.field == "lateCount")[0]
            ?.tableData.filterValue ?? "",
      });
    }

    columns.push({
      title: "Actions",
      cellStyle: {
        padding: 0,
        width: "1%",
        textAlign: "center",
      },
      render: (row) => (
        <>
          <Button onClick={(e) => openMenu(e, row.id)}>
            <SettingsIcon
              sx={{
                color: "#808080",
              }}
            />
          </Button>
          <Menu
            anchorEl={anchorEl && anchorEl[row.id]}
            open={Boolean(anchorEl && anchorEl[row.id])}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            getContentAnchorEl={null}>
            <MenuItem
              onClick={() => {
                downloadPayrollReport(row.id, false).catch((e) => console.error(e));
                handleMenuClose();
              }}>
              Payroll Report
            </MenuItem>
            <CanRenderChildren featureFlagName="isBillingOn">
              <MenuItem
                onClick={() => {
                  downloadBillingReport(row.id, false).catch((e) => console.error(e));
                  handleMenuClose();
                }}>
                Billing Report
              </MenuItem>
            </CanRenderChildren>
            <CanRenderChildren permissionName="canRetryNetSuite">
              {(row.netSuiteLockState === NetSuiteLockState.RegularTimesheetsFailed ||
                row.netSuiteLockState === NetSuiteLockState.LateTimesheetsFailed) && (
                <MenuItem
                  disabled={loading && loading.get(row.id)}
                  onClick={() => {
                    retryNetsuiteUpload(row.id);
                    handleMenuClose();
                  }}>
                  Retry NetSuite Upload
                </MenuItem>
              )}
            </CanRenderChildren>
          </Menu>
        </>
      ),
    });
    if (order) {
      const column = columns[profileTableRef.current?.state?.orderBy];
      const filterOrder = { defaultSort: order };
      columns[profileTableRef.current?.state?.orderBy] = { ...column, ...filterOrder };
    }
    return columns;
  };

  useEffect(() => {
    dispatch({ type: PROFILE_TABLE_SWITCH_NETSUITE_CHANGED, switchState: false });

    getMvpUsers();
  }, []);

  const getMvpUsers = () => {
    getMvpUsersApi()
      .then((usersInfo) => {
        // get timesheet period start and end
        setTimesheetStart(usersInfo.item1);
        setTimesheetEnd(usersInfo.item2);
        setMvpusers(usersInfo.item3);
        setFilteredUsers(usersInfo.item3);
        setLockPayrollDialogOpen(false);
        dispatch({ type: PROFILE_TABLE_SWITCH_NETSUITE_CHANGED, switchState: false });

        PayrollLocksApi.GetPayrollLockStatusByType(PayrollLockType.RegularTimecard)
          .then((result) => {
            setLateTimecardsReleased(result.IsLocked);
          })
          .catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));
  };

  const retryNetsuiteUpload = (recruiterId) => {
    loading.set(recruiterId, true);
    setLoading(loading);
    const data = { periodEnd: timesheetEnd, periodStart: timesheetStart, recruiterId: recruiterId };
    BillingApi.billingUpload(data)
      .then((result) => {
        loading.set(recruiterId, false);
        setLoading(loading);
        if (result.isUploaded) {
          getMvpUsers();
          props.enqueueSnackbar("NetSuite upload successful.");
        } else {
          props.enqueueSnackbar("NetSuite upload retry failed.");
        }
      })
      .catch((e) => console.error(e));
  };

  /**
   * @param {boolean} e
   */
  const openTriagePlusReportDownloadDialog = (e) => {
    setIsTriagePlusReportDowloadDialogOpen(e);
  };

  /**
   * @param {boolean} e
   */
  const handleDownloadLoader = (e) => {
    setIsLoadingTriagePlusReportDowload(e);
  };

  return (
    <div className="view-container">
      {filteredUsers == null ? (
        <CircularProgress />
      ) : (
        <>
          <CanRenderChildren permissionName="canSeeButtonsProfile">
            <Box mb={2} mt={1} display={"flex"} flexWrap={"wrap"} gap={2}>
              <ButtonGroup variant="contained">
                <Button
                  onClick={() => {
                    openPayrollDialog(PayrollLockType.RegularTimecard);
                  }}>
                  UltiPro
                </Button>
                <Button
                  onClick={() => openPayrollDialog(PayrollLockType.LateAndAdjustmentTimecard)}>
                  Late UltiPro
                </Button>
              </ButtonGroup>

              <CanRenderChildren featureFlagName="isAdpCodeOn">
                <ButtonGroup variant="contained">
                  <Button onClick={() => downloadAdpReport(false)}>Adp</Button>
                  <Button onClick={() => downloadAdpReport(true)}>Late Adp</Button>
                </ButtonGroup>
              </CanRenderChildren>

              <ButtonGroup variant="contained">
                <Button
                  onClick={() => {
                    downloadPayrollReport("", false, false).catch((e) => console.error(e));
                  }}>
                  Payroll
                </Button>
                <Button
                  onClick={() => {
                    downloadPayrollReport("", true, true).catch((e) => console.error(e));
                  }}>
                  Late Payroll
                </Button>
              </ButtonGroup>

              <CanRenderChildren featureFlagName="isBillingOn">
                <ButtonGroup variant="contained">
                  <Button
                    onClick={() => {
                      downloadBillingReport("", false).catch((e) => console.error(e));
                    }}>
                    Billing
                  </Button>
                  <Button
                    onClick={() => {
                      downloadBillingReport("", true).catch((e) => console.error(e));
                    }}>
                    Late Billing
                  </Button>
                </ButtonGroup>
              </CanRenderChildren>

              <CanRenderChildren featureFlagName="isAdjustmentsOn">
                <Button
                  variant="contained"
                  onClick={() => {
                    downloadPayrollReport("", false, true, true).catch((e) => console.error(e));
                  }}>
                  Adjustment Billing
                </Button>
              </CanRenderChildren>

              <CanRenderChildren permissionName="canDownloadTriagePlusReport">
                <LoadingButton
                  onClick={openTriagePlusReportDownloadDialog}
                  variant="contained"
                  loading={isLoadingTriagePlusReportDowload}>
                  Timecard Import
                </LoadingButton>
              </CanRenderChildren>

              <div>
                <LockPayrollDialog
                  open={isLockPayrollDialogOpen}
                  onClose={closePayrollDialog}
                  onPayrollReport={lockAndDownloadPayrollRawDataReport}
                  onNoFinalReport={noFinalReport}
                  lockType={lockType}
                />
              </div>
            </Box>

            <TriagePlusReportDownloadDialog
              open={isTriagePlusReportDowloadDialogOpen}
              onClose={openTriagePlusReportDownloadDialog}
              toggleLoader={handleDownloadLoader}
            />
          </CanRenderChildren>

          {filteredUsers != null && (
            <div style={{ display: "grid" }}>
              <MaterialTable
                style={{ overflow: "auto", fontSize: 14 }}
                tableRef={profileTableRef}
                onSearchChange={handleTableChange}
                onFilterChange={handleTableChange}
                onOrderChange={handleTableChange}
                columns={loadColumns()}
                data={filteredUsers}
                components={{
                  Toolbar: ProfileToolbar,
                }}
                onChangePage={handleTableChange}
                options={{
                  searchText: profileTableRef.current?.state?.searchText ?? "",
                  sorting: true,
                  filtering: true,
                  showTitle: false,
                  maxBodyHeight: "66vh",
                  pageSize: 20,
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

Profile.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  featureFlags: PropTypes.array,
  profile: PropTypes.object,
  profileChanged: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile?.profile,
});

const mapDispatchToProps = (dispatch) => ({
  profileChanged: (profile) => dispatch({ type: PROFILE_TABLE_CHANGED, profile }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Profile));
