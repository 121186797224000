import moment from "moment";
import PayrollApi from "api/PayrollApi";
import { downloadBlob } from "commons/utils";
import { getAdpReport } from "api/DataExportApi";

export async function downloadPayrollReport(
  recruiterId: string,
  lateTimecards: boolean,
  adjustmentTimecards: boolean,
  onlyAdjustedLines: boolean
): Promise<void> {
  try {
    const blob = await PayrollApi.downloadPayrollRecruiterReport(
      recruiterId,
      lateTimecards,
      adjustmentTimecards,
      onlyAdjustedLines
    );
    await downloadBlob(new Blob([blob]), recruiterId ? `report_${recruiterId}.csv` : `report.csv`);
  } catch (error) {
    alert(error);
  }
}

export async function downloadBillingReport(
  recruiterId: string,
  lateTimecards: boolean
): Promise<void> {
  try {
    const blob = await PayrollApi.downloadBillingRecruiterReport(recruiterId, lateTimecards);
    await downloadBlob(new Blob([blob]), recruiterId ? `report_${recruiterId}.csv` : `report.csv`);
  } catch (error) {
    alert(error);
  }
}

export async function downloadPaycorReport(reportType: string): Promise<void> {
  const curr = new Date();
  const firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
  const lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));

  const fd = moment(firstday).subtract(7, "days");
  const ld = moment(lastday).subtract(7, "days");

  try {
    const blob = await PayrollApi.downloadPayrollPaycorReport(reportType, fd, ld);
    await downloadBlob(
      new Blob([blob]),
      `PaycorReport_${fd.format("MM-DD-YYYY")}_${ld.format("MM-DD-YYYY")}.csv`
    );
  } catch (error) {
    alert(error);
  }
}

export async function downloadAdpReport(lateTimecards: boolean) {
  const blob = await getAdpReport(lateTimecards);

  await downloadBlob(new Blob([blob]), `ADPUpload.csv`);
}
