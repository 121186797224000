import { PERMISSIONS_CHANGED } from "../actions/actionTypes";
import { Roles } from "commons/Enums";

type PermissionState = {
  canManageFacilities: boolean;
  canSeeButtonsProfile: boolean;
  canSeeExpenses: boolean;
  canCreateExpenses: boolean;
  canUploadExpenseFile: boolean;
  canSeeVms: boolean;
  canUnlockPayPeriod: boolean;
  canSeeFacilitiesColumnLink: boolean;
  canManageFacilitiesDocuments: boolean;
  canSeeFacilitiesRecentlyAddedToggle: boolean;
  canSeeTotalAmountExpenseSectionPanel: boolean;
  canSeeLinkToExpenseSectionPanel: boolean;
  canSeeTotalAmountTaxableExpense: boolean;
  canSeeReturnToPending: boolean;
  canSeeTimecardBilling: boolean;
  canSeeTimecardApprove: boolean;
  canSeeConfirmationPageFile: boolean;
  canAddTraveler: boolean;
  canOverrideWorkedHoursMissedShiftCalculation: boolean;
  canEditMileage: boolean;
  canEditPerDiemAndHousing: boolean;
  canSeeTotalInTimecardHours: boolean;
  canEditRateInTimecardHours: boolean;
  canAdjustCurrentTimecard: boolean;
  canRetryNetSuite: boolean;
  canCreateTimecardAjustment: boolean;
  canFilterByRecruiterAdvancedSearch: boolean;
  canResetDidNotWork: boolean;
  canCheckNextPayPeriodTimesheets: boolean;
  canSeeDashboard: boolean;
  canSeeProfile: boolean;
  canOnlySeeTimesheetProcessing: boolean;
  canCheckHolidays: boolean;
  canRejectTimecard: boolean;
  canEditDtHours: boolean;
  canRequestSignature: boolean;
  canSeeReferrals: boolean;
  canExportReferrals: boolean;
  canRequestReferrerPaperwork: boolean;
  canSeeReferrerPaperwork: boolean;
  canDownloadReferrerPaperwork: boolean;
  canSendEmailReminderReferrerPaperwork: boolean;
  canSeeShowErrorToggleReferrals: boolean;
  canNotifyToTraveler: boolean;
  canOpenTimecardAutomatically: boolean;
  canSeeManagersList: boolean;
  canReassignManager: boolean;
  canManageContracts: boolean;
  canSeeContracts: boolean;
  canReplyTextMessages: boolean;
  canDowloadFacilityTimecards: boolean;
  canApproveDeclineEditRequest: boolean;
  canAddFilesToFacilityReport: boolean;
  canManageUsers: boolean;
  canSaveAssignmentNotes: boolean;
  canDownloadTriagePlusReport: boolean;
  canDragAndDropInDraftStatus: boolean;
  canUpdateFacilityReportFile: boolean;
  canUpdateConfirmationPageFile: boolean;
  canUpdateTimecardFileInBillingStatus: boolean;
  canManageAdpCode: boolean;
};

const initialState: PermissionState = {
  canManageFacilities: false,
  canSeeButtonsProfile: false,
  canSeeExpenses: false,
  canCreateExpenses: false,
  canUploadExpenseFile: false,
  canSeeVms: false,
  canUnlockPayPeriod: false,
  canSeeFacilitiesColumnLink: false,
  canManageFacilitiesDocuments: false,
  canSeeFacilitiesRecentlyAddedToggle: false,
  canSeeTotalAmountExpenseSectionPanel: false,
  canSeeLinkToExpenseSectionPanel: false,
  canSeeTotalAmountTaxableExpense: false,
  canSeeReturnToPending: false,
  canSeeTimecardBilling: false,
  canSeeTimecardApprove: false,
  canSeeConfirmationPageFile: false,
  canAddTraveler: false,
  canOverrideWorkedHoursMissedShiftCalculation: false,
  canEditMileage: false,
  canEditPerDiemAndHousing: false,
  canSeeTotalInTimecardHours: false,
  canEditRateInTimecardHours: false,
  canAdjustCurrentTimecard: false,
  canRetryNetSuite: false,
  canCreateTimecardAjustment: false,
  canFilterByRecruiterAdvancedSearch: false,
  canResetDidNotWork: false,
  canCheckNextPayPeriodTimesheets: false,
  canSeeDashboard: false,
  canSeeProfile: false,
  canOnlySeeTimesheetProcessing: false,
  canExportReferrals: false,
  canCheckHolidays: false,
  canRejectTimecard: false,
  canEditDtHours: false,
  canRequestSignature: false,
  canRequestReferrerPaperwork: false,
  canSeeReferrerPaperwork: false,
  canDownloadReferrerPaperwork: false,
  canSendEmailReminderReferrerPaperwork: false,
  canSeeShowErrorToggleReferrals: false,
  canNotifyToTraveler: false,
  canOpenTimecardAutomatically: false,
  canSeeManagersList: false,
  canReassignManager: false,
  canManageContracts: false,
  canSeeContracts: false,
  canReplyTextMessages: false,
  canDowloadFacilityTimecards: false,
  canApproveDeclineEditRequest: false,
  canAddFilesToFacilityReport: false,
  canManageUsers: false,
  canSeeReferrals: false,
  canSaveAssignmentNotes: false,
  canDownloadTriagePlusReport: false,
  canDragAndDropInDraftStatus: false,
  canUpdateFacilityReportFile: false,
  canUpdateConfirmationPageFile: false,
  canUpdateTimecardFileInBillingStatus: false,
  canManageAdpCode: false,
};

type Role =
  | "SuperAdmin"
  | "Admin"
  | "RecruiterTeamLead"
  | "Recruiter"
  | "RecruiterAssistant"
  | "SalesRep"
  | "PayPeriodUnlocker"
  | "Triage+.SuperAdmin"
  | "Triage+.PayPeriodUnlocker";

type PermissionsAction = {
  type: string;
  roles: Role[];
};

export default function permissionsReducer(
  state = initialState,
  action: PermissionsAction
): PermissionState {
  switch (action.type) {
    case PERMISSIONS_CHANGED: {
      const newState = Object.assign({}, state);
      const rolesToCheck = action.roles || [];
      rolesToCheck.forEach((role) => {
        switch (role) {
          case Roles.TriagePlusSuperAdmin:
            newState.canManageFacilities = true;
            newState.canSeeButtonsProfile = true;
            newState.canSeeVms = true;
            newState.canSeeFacilitiesColumnLink = true;
            newState.canManageFacilitiesDocuments = true;
            newState.canSeeFacilitiesRecentlyAddedToggle = true;
            newState.canSeeTotalAmountExpenseSectionPanel = true;
            newState.canSeeTotalAmountTaxableExpense = true;
            newState.canSeeReturnToPending = true;
            newState.canSeeTimecardBilling = true;
            newState.canSeeTimecardApprove = true;
            newState.canSeeConfirmationPageFile = true;
            newState.canAddTraveler = true;
            newState.canOverrideWorkedHoursMissedShiftCalculation = true;
            newState.canEditMileage = true;
            newState.canEditPerDiemAndHousing = true;
            newState.canSeeTotalInTimecardHours = true;
            newState.canEditRateInTimecardHours = true;
            newState.canAdjustCurrentTimecard = true;
            newState.canRetryNetSuite = true;
            newState.canCreateTimecardAjustment = true;
            newState.canFilterByRecruiterAdvancedSearch = true;
            newState.canResetDidNotWork = true;
            newState.canCheckNextPayPeriodTimesheets = true;
            newState.canSeeDashboard = true;
            newState.canSeeProfile = true;
            newState.canRejectTimecard = true;
            newState.canEditDtHours = true;
            newState.canRequestSignature = true;
            newState.canOpenTimecardAutomatically = true;
            newState.canSeeManagersList = true;
            newState.canReassignManager = true;
            newState.canSeeContracts = true;
            newState.canManageContracts = true;
            newState.canDowloadFacilityTimecards = true;
            newState.canApproveDeclineEditRequest = true;
            newState.canAddFilesToFacilityReport = true;
            newState.canSaveAssignmentNotes = true;
            newState.canDownloadTriagePlusReport = true;
            newState.canDragAndDropInDraftStatus = true;
            newState.canUpdateConfirmationPageFile = true;
            newState.canUpdateTimecardFileInBillingStatus = true;
            newState.canUpdateFacilityReportFile = true;
            break;

          case Roles.SuperAdmin:
            newState.canManageFacilities = true;
            newState.canSeeButtonsProfile = true;
            newState.canSeeExpenses = true;
            newState.canCreateExpenses = true;
            newState.canUploadExpenseFile = true;
            newState.canSeeVms = true;
            newState.canSeeFacilitiesColumnLink = true;
            newState.canManageFacilitiesDocuments = true;
            newState.canSeeFacilitiesRecentlyAddedToggle = true;
            newState.canSeeTotalAmountExpenseSectionPanel = true;
            newState.canSeeLinkToExpenseSectionPanel = true;
            newState.canSeeTotalAmountTaxableExpense = true;
            newState.canSeeReturnToPending = true;
            newState.canSeeTimecardBilling = true;
            newState.canSeeTimecardApprove = true;
            newState.canSeeConfirmationPageFile = true;
            newState.canAddTraveler = true;
            newState.canOverrideWorkedHoursMissedShiftCalculation = true;
            newState.canEditMileage = true;
            newState.canEditPerDiemAndHousing = true;
            newState.canSeeTotalInTimecardHours = true;
            newState.canEditRateInTimecardHours = true;
            newState.canAdjustCurrentTimecard = true;
            newState.canRetryNetSuite = true;
            newState.canCreateTimecardAjustment = true;
            newState.canFilterByRecruiterAdvancedSearch = true;
            newState.canResetDidNotWork = true;
            newState.canCheckNextPayPeriodTimesheets = true;
            newState.canSeeDashboard = true;
            newState.canSeeProfile = true;
            newState.canCheckHolidays = true;
            newState.canRejectTimecard = true;
            newState.canEditDtHours = true;
            newState.canRequestSignature = true;
            newState.canSeeReferrals = true;
            newState.canExportReferrals = true;
            newState.canRequestReferrerPaperwork = true;
            newState.canSeeReferrerPaperwork = true;
            newState.canDownloadReferrerPaperwork = true;
            newState.canSendEmailReminderReferrerPaperwork = true;
            newState.canSeeShowErrorToggleReferrals = true;
            newState.canNotifyToTraveler = true;
            newState.canOpenTimecardAutomatically = true;
            newState.canSeeManagersList = true;
            newState.canReassignManager = true;
            newState.canSeeContracts = true;
            newState.canManageContracts = true;
            newState.canReplyTextMessages = true;
            newState.canDowloadFacilityTimecards = true;
            newState.canApproveDeclineEditRequest = true;
            newState.canAddFilesToFacilityReport = true;
            newState.canManageUsers = true;
            newState.canSaveAssignmentNotes = true;
            newState.canDownloadTriagePlusReport = true;
            newState.canDragAndDropInDraftStatus = true;
            newState.canUpdateConfirmationPageFile = true;
            newState.canUpdateTimecardFileInBillingStatus = true;
            newState.canUpdateFacilityReportFile = true;
            newState.canManageAdpCode = true;
            break;

          case Roles.Admin:
            newState.canManageFacilities = true;
            newState.canSeeButtonsProfile = true;
            newState.canSeeExpenses = true;
            newState.canCreateExpenses = true;
            newState.canUploadExpenseFile = true;
            newState.canSeeVms = true;
            newState.canSeeLinkToExpenseSectionPanel = true;
            newState.canSeeReturnToPending = true;
            newState.canSeeTimecardBilling = true;
            newState.canSeeTimecardApprove = true;
            newState.canSeeConfirmationPageFile = true;
            newState.canAddTraveler = true;
            newState.canOverrideWorkedHoursMissedShiftCalculation = true;
            newState.canEditMileage = true;
            newState.canSeeTotalInTimecardHours = true;
            newState.canFilterByRecruiterAdvancedSearch = true;
            newState.canSeeDashboard = true;
            newState.canSeeProfile = true;
            newState.canRejectTimecard = true;
            newState.canEditDtHours = true;
            newState.canSeeReferrals = true;
            newState.canSeeReferrerPaperwork = true;
            newState.canSeeShowErrorToggleReferrals = true;
            newState.canNotifyToTraveler = true;
            newState.canOpenTimecardAutomatically = true;
            newState.canReassignManager = true;
            newState.canSeeContracts = true;
            newState.canReplyTextMessages = true;
            newState.canApproveDeclineEditRequest = true;
            newState.canSaveAssignmentNotes = true;
            newState.canSeeFacilitiesRecentlyAddedToggle = true;
            newState.canDragAndDropInDraftStatus = true;
            newState.canUpdateConfirmationPageFile = true;
            newState.canUpdateTimecardFileInBillingStatus = true;
            newState.canUpdateFacilityReportFile = true;
            newState.canManageAdpCode = true;
            break;

          case Roles.RecruiterTeamLead:
            newState.canSeeExpenses = true;
            newState.canCreateExpenses = true;
            newState.canUploadExpenseFile = true;
            newState.canSeeLinkToExpenseSectionPanel = true;
            newState.canSeeTimecardBilling = true;
            newState.canSeeTimecardApprove = true;
            newState.canFilterByRecruiterAdvancedSearch = true;
            newState.canSeeProfile = true;
            newState.canSeeReferrals = true;
            newState.canUpdateTimecardFileInBillingStatus = true;
            newState.canUpdateFacilityReportFile = true;
            break;

          case Roles.Recruiter:
            newState.canSeeExpenses = true;
            newState.canCreateExpenses = true;
            newState.canUploadExpenseFile = true;
            newState.canSeeLinkToExpenseSectionPanel = true;
            newState.canSeeTimecardBilling = true;
            newState.canSeeTimecardApprove = true;
            newState.canOnlySeeTimesheetProcessing = true;
            newState.canSeeReferrals = true;
            newState.canUpdateTimecardFileInBillingStatus = true;
            newState.canUpdateFacilityReportFile = true;
            break;

          case Roles.Assistant:
            newState.canSeeTimecardBilling = true;
            newState.canSeeTimecardApprove = true;
            newState.canFilterByRecruiterAdvancedSearch = true;
            newState.canSeeProfile = true;
            newState.canSeeExpenses = true;
            newState.canCreateExpenses = true;
            newState.canUploadExpenseFile = true;
            newState.canSeeReferrals = true;
            newState.canUpdateTimecardFileInBillingStatus = true;
            newState.canUpdateFacilityReportFile = true;
            break;

          case Roles.SalesRep:
            newState.canSeeExpenses = true;
            newState.canFilterByRecruiterAdvancedSearch = true;
            newState.canOnlySeeTimesheetProcessing = true;
            newState.canSeeReferrals = true;
            break;

          case Roles.PayPeriodUnlocker:
            newState.canUnlockPayPeriod = true;
            break;

          default:
            break;
        }
      });

      return newState;
    }
    default:
      return state;
  }
}
